* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-body {
  background: linear-gradient(#141e30, #243b55);
}

.card-container {
  width: 80%;
  min-height: 60vh;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.card-center {
  align-items: center;
  justify-content: center;
}

.card {
  background: rgba(0, 0, 0, 0.5);
  width: 350px;
  min-height: 426.3px;
  display: -ms-flexbox;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 5px;
  box-shadow: -20px -20px 0px 0px #1c2942;
  border-radius: 10px;
  -webkit-animation-name: shadow-show; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  animation-name: shadow-show;
  animation-duration: 1.5s;
  transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1); /* custom */
}
.card .card h2 {
  margin: 0px;
  padding: 0px 0px 15px 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  color: #243b55;
}
.card .card hr {
  display: block;
  border: none;
  height: 3px;
  background-color: #141e30;
  margin: 0px;
  -webkit-animation-name: line-show; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.3s; /* Safari 4.0 - 8.0 */
  animation-name: line-show;
  animation-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1); /* custom */
}
.card .card p {
  margin: 15px 0px 0px 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 100;
  letter-spacing: -0.25px;
  line-height: 1.25;
  font-size: 16px;
  word-break: break-all;
  word-wrap: pre-wrap;
  color: #243b55;
  -webkit-animation-name: p-show; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  animation-name: p-show;
  animation-duration: 1.5s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes line-show {
  from {
    margin: 0px 100px;
  }
  to {
    margin: 0px;
  }
}
/* Standard syntax */
@keyframes line-show {
  from {
    margin: 0px 100px;
  }
  to {
    margin: 0px;
  }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes p-show {
  from {
    color: white;
  }
  to {
    color: #243b55;
  }
}
/* Standard syntax */
@keyframes p-show {
  from {
    color: white;
  }
  to {
    color: #243b55;
  }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes shadow-show {
  from {
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    box-shadow: -20px -20px 0px 0px #1c2942;
  }
}
/* Standard syntax */
@keyframes shadow-show {
  from {
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    box-shadow: -20px -20px 0px 0px #1c2942;
  }
}/*# sourceMappingURL=card.css.map */