body {
  background: linear-gradient(#141e30, #243b55);
  box-sizing: border-box;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin-top: 60px;
  padding: 30px;
  font-family: sans-serif;
  margin-left: 30vw;
  margin-right: 30vw;
}
.inner-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}
button {
  border: none;
  padding: 10px 20px;
  position: relative;
  background: transparent;
  color: #03e9f4;
  text-transform: uppercase;
  border: 2px solid #03e9f4;
  cursor: pointer;
  transition: all 0.3s;
  overflow: hidden;
  border-radius: 100px;
  margin: 5px;
}
button:hover {
  color: #fff;
}
span {
  transition: all 0.7s;
  z-index: -1;
}

button .first {
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 25%;
  height: 100%;
  background: #03e9f4;
}

button:hover .first {
  top: 0;
  right: 0;
}
button .second {
  content: "";
  position: absolute;
  left: 25%;
  top: -100%;
  height: 100%;
  width: 25%;
  background: #03e9f4;
}

button:hover .second {
  top: 0;
  left: 50%;
}

button .third {
  content: "";
  position: absolute;
  left: 50%;
  height: 100%;
  top: 100%;
  width: 25%;
  background: #03e9f4;
}

button:hover .third {
  top: 0;
  left: 25%;
}

button .fourth {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 25%;
  background: #03e9f4;
}

button:hover .fourth {
  top: 0;
  left: 0;
}
.inner-container input {
  font-size: 15px;
  padding: 10px;
  margin: 5px;
  color: #fff;
  border: 0px;
  border-bottom: 1px solid white;
  background-color: transparent;
}
.inner-container input:focus {
  color: #03e9f4;
  background-color: transparent;
  outline: 0;

}
.logoutBtn {
  display: none;
}
.signup {
  margin: 0px;
  position: relative;
  left: 8vw;
  color: #03e9f4;
  text-decoration: dotted;
}
.signup:hover {
  cursor: pointer;
  color: #fff;
}
.error {
  color: red;
}

.inner-container input::placeholder {
  color: rgb(228, 224, 224);
}

@media screen and (min-width:460px)and (max-width:800px) {
  .login-container{
    margin-left: 20vw;
    margin-right: 20vw;
  }
  .signup{
    left: 16vw;
  }
}
@media screen and (max-width:460px) {
  .login-container{
    margin-left: 15vw;
    margin-right: 15vw;
  }
  .signup{
    left: 16vw;
  }
  button{
    font-size: 0.8rem;
  }
}


.feed-inputs, .feed-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}