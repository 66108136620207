$point: #141e30;
$black: #243b55;
$point-light: lighten($point, 5%);

$ratio: 1.218;
$card_width: 350px;
$card_height: $card_width * $ratio;
$card_padding: 20px;
$card-bgcolor: black;
$card_margin: 5px;
$card_round: 10px;
$card-shadow: -20px -20px 0px 0px $point-light;

$card-padding-basis: 15px;
$card_head_padding: 0px 0px $card-padding-basis 0px;

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-body{
  background: linear-gradient(#141e30, #243b55);
//   width: 400px;
}

.card-container{
  width: 80%;
  min-height: 60vh;  
  display: -webkit-box;  
  display: -ms-flexbox;  
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.card-center{
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.card{
  background: rgba(0, 0, 0, 0.5);
  width: $card_width;
  min-height: $card_height;
  
  display: -webkit-box;
  
  display: -ms-flexbox;
  font-size: 1.1em;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: $card_padding;
  margin: $card_margin;
  
  -webkit-box-shadow: $card-shadow;
  
          box-shadow: $card-shadow;
  border-radius: $card_round;
  
  -webkit-animation-name: shadow-show; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  animation-name: shadow-show;
  animation-duration: 1.5s;
  
  -webkit-transition-timing-function: cubic-bezier(0.795, 0.000, 0.165, 1.000);
  -o-transition-timing-function: cubic-bezier(0.795, 0.000, 0.165, 1.000);
  transition-timing-function: cubic-bezier(0.795, 0.000, 0.165, 1.000); /* custom */

  
  .card h2{
    margin: 0px;
    padding: $card_head_padding;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 30px;
    color: $black;
  }
  
  .card hr{
    display: block;
    border: none;
    height: 3px;
    background-color: $point;
    margin: 0px;
    
    -webkit-animation-name: line-show; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.3s; /* Safari 4.0 - 8.0 */
    animation-name: line-show;
    animation-duration: 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.795, 0.000, 0.165, 1.000);
    -o-transition-timing-function: cubic-bezier(0.795, 0.000, 0.165, 1.000);
    transition-timing-function: cubic-bezier(0.795, 0.000, 0.165, 1.000); /* custom */
  }
  
  .card p{
    margin: $card-padding-basis 0px 0px 0px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 100;
    letter-spacing: -0.25px;
    line-height: 1.25;
    font-size: 16px;
    word-break: break-all;
    word-wrap: pre-wrap;
    color: $black;
    
    -webkit-animation-name: p-show; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
    animation-name: p-show;
    animation-duration: 1.5s;
  }
  
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes line-show {
  from {margin: 0px 100px;}
  to {margin: 0px;}
}

/* Standard syntax */
@keyframes line-show {
  from {margin: 0px 100px;}
  to {margin: 0px;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes p-show {
  from {color: white;}
  to {color: $black;}
}

/* Standard syntax */
@keyframes p-show {
  from {color: white;}
  to {color: $black;}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes shadow-show {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: $card-shadow;
    box-shadow: $card-shadow;
  }
}

/* Standard syntax */
@keyframes shadow-show {
  from {-webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;box-shadow: 0px 0px 0px 0px #e0e0e0;}
  to {-webkit-box-shadow: $card-shadow;box-shadow: $card-shadow;}
}